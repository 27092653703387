import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default function controller() {
  const blankItem = {
    id: null,
    name: null,
  }
  const form = ref(null)
  const items = ref([])
  const item = ref({})
  const tableColumns = [
    { text: 'Nom', value: 'name' },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const deleteDialog = ref(false)
  const searchQuery = ref('')
  const totalItems = ref(0)
  const loading = ref(false)
  const options = ref({
    rowsPerPage: 10,
    page: 1,
    sortDesc: [false],
  })
  const page = ref(1)
  const pageCount = ref(0)
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }

  // new item
  const openDialog = val => {
    if (val) {
      item.value = val
    } else {
      item.value = JSON.parse(JSON.stringify(blankItem))
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }

  // fetch data
  const fetchItems = () => {
    isFormActive.value = false
    store
      .dispatch('origin/fetchItems', {
        term: searchQuery.value,
        orderBy: options.value.sortBy ? (options.value.sortBy[0] || 'id') : 'id',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        const { data, total } = response.data

        items.value = data
        totalItems.value = total

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
      .then(() => {
        loading.value = false
      })
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      store.dispatch('origin/deleteItem', item.value.id)
        .then(response => {
          deleteDialog.value = false
          fetchItems()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {

        })
    }
  }

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchItems()
  })

  return {
    item,
    items,
    tableColumns,
    searchQuery,
    totalItems,
    loading,
    options,
    selectedRows,
    isFormActive,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    deleteDialog,
    resetForm,
    openDialog,
    confirmDelete,
    deleteItem,
    fetchItems,
  }
}
